import "./App.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import { FaCrown } from "react-icons/fa";

function Navbar() {
  return (
    <nav
      style={{
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "lightblue",
      }}
    >
      <a href="/">Nominations</a>
      <Nominate />
    </nav>
  );
}

// A component that has an entry field and a submit button
// When the submit button is clicked, the entry is sent to the backend
// Then the page for the nomination is loaded
function Nominate() {
  const [name, setName] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.get("/api/entry/" + name);
      window.location.href = `/${name}`;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter name"
        required
        style={{
          flex: 1,
          padding: "10px",
          marginRight: "10px",
          boxSizing: "border-box",
        }}
      />
      <button type="submit" style={{ padding: "10px" }}>
        Nominate
      </button>
    </form>
  );
}

function Entry() {
  const { name } = useParams();
  const [entry, setEntry] = useState([]);

  useEffect(() => {
    fetchEntry(name);
  }, [name]);

  const fetchEntry = async (name) => {
    try {
      const response = await axios.get("/api/entry/" + name);
      setEntry(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const endorseEntry = async (name) => {
    try {
      const response = await axios.post(
        "/api/endorse/" + name,
      );
      setEntry(response.data);
      fetchEntry(name);
    } catch (error) {
      console.error(error);
    }
  };

  const endorseButton = () => {
    return <button onClick={() => endorseEntry(name)}>endorse</button>;
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      {entry ? (
        <div>
          <h2>KYS {entry.name}</h2>
          <p>
            {entry.count} people {endorseButton()} this message
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

function Nominations() {
  const [nominations, setNominations] = useState([]);

  useEffect(() => {
    fetchNominations();
  }, []);

  const fetchNominations = async () => {
    try {
      const response = await axios.get("/api/entries");
      response.data.sort((a, b) => b.count - a.count);
      setNominations(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h2>Nominations</h2>
      <table
        style={{ width: "100%", borderCollapse: "collapse", textAlign: "left" }}
      >
        <thead>
          <tr>
            <th style={{ paddingBottom: "10px" }}>#</th>
            <th style={{ paddingBottom: "10px" }}>Name</th>
            <th style={{ paddingBottom: "10px" }}>Count</th>
          </tr>
        </thead>
        <tbody>
          {nominations.map((entry, index) => (
            <tr
              key={entry.name}
              style={index === 0 ? { fontWeight: "bold", color: "gold" } : {}}
            >
              <td
                style={{
                  padding: "10px",
                  width: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {index + 1}
                {index === 0 && (
                  <FaCrown style={{ marginLeft: "5px", color: "gold" }} /> // Crown icon to the right of the number
                )}
              </td>
              <td style={{ padding: "10px" }}>
                <a
                  href={`/${entry.name}`}
                  style={
                    index === 0
                      ? { fontWeight: "bold", color: "gold" }
                      : { color: "inherit", textDecoration: "none" }
                  }
                >
                  {entry.name}
                </a>
              </td>
              <td
                style={{ padding: "10px", width: "50px", textAlign: "right" }}
              >
                {entry.count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/:name" element={<Entry />} />
        <Route path="/" element={<Nominations />} />
      </Routes>
    </Router>
  );
}

export default App;
